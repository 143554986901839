import React from 'react';
import styled from 'styled-components';
import InvestmentContainer from '../view/invetstment/container/InvestmentContainer';


const Investment = () => {
    return (
    <Container>
        <InvestmentContainer/>
    </Container>
    );
};




const Container = styled.div `

 
`;


export default Investment;