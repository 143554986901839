import React from 'react';
import styled from 'styled-components';
import CompanyContainer from '../view/company/container/CompanyContainer';


const Company = () => {
    return (
    <Container>
         <CompanyContainer/>
    </Container>
    );
};




const Container = styled.div `

 
`;


export default Company;