import React from 'react';
import styled from 'styled-components';
import NoticeDetailContainer from '../view/notice/container/NoticeDetailContainer';


const NoticeDetail = () => {
    return (
    <Container>
        <NoticeDetailContainer/>
    </Container>
    );
};





const Container = styled.div `

 
`;


export default NoticeDetail;