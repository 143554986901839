import React from 'react';
import styled from 'styled-components';
import IrContent from '../components/IrContents';


const IrContainer = () => {
    return (
    <Container>
        <IrContent/>
    </Container>
    );
};




const Container = styled.div `

 
`;


export default IrContainer;