import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Roboto','Noto Sans KR', sans-serif;
    
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
      padding: 0;
      margin: 0;
      list-style: none;
      -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;
      text-decoration: none;
    }
    
    img {
      max-width:100%;
      vertical-align: top;
    }

    button {
        padding: 0;
        margin: 0;
        background: none;
        border:none;
        box-shadow:none;
        cursor: pointer;
    }

    a {
      text-decoration: none;
    }
    .hidden-mobile {
        display: block;
        transition: 1s 0.4s;
        @media screen and (max-width: 1600px) and (min-width: 1024px) {
          display:none;
        }
        @media screen and (max-width:1024px) {
          display: none;
        }
    }
    .hidden-desktop {
      display: none;
   
      @media screen and (max-width:1024px) {
        transition: 1s 0.4s;
        display:block;
      }
    }
    
// Generate Duration && Delay
[data-aos] {
  @for $i from 1 through 60 {
    body[data-aos-duration='#{$i * 50}'] &,
    &[data-aos][data-aos-duration='#{$i * 50}'] {
      transition-duration: #{$i * 50}ms;
    }

    body[data-aos-delay='#{$i * 50}'] &,
    &[data-aos][data-aos-delay='#{$i * 50}'] {
      transition-delay: 0;

      &.aos-animate {
        transition-delay: #{$i * 50}ms;
      }
    }
  }
}
`;
